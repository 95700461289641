import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import ProjectService from '../../services/projectService';
import classes from './UserProjects.module.scss';
import Spinner from '../../components/common/Spinner/Spinner';
import ProjectsList from '../../components/ProjectsList/ProjectsList';

const UserProjects = () => {
    const [projects, setProjects] = useState();

    const navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        const res = await ProjectService.getAllForUser()
        setProjects(res)
    }

    const clickHandler = (id) => {
        navigate('/project/details/' + id, { state: { id } })
    }

    if (!projects) {
        return <Spinner />
    }

    if (projects.length === 0) {
        return (<h1 className={classes.Warning}>No projects yet</h1>)
    }

    return (
        <Container>
            <ProjectsList projects={projects} clickHandler={clickHandler} title="MY PROJECTS" />
        </Container>
    )
}

export default UserProjects;