import { Row, Col, Container } from 'react-bootstrap';
import classes from './PlatformSummary.module.scss';

const PlatformSummary = () => {
  return (
    <Container>
      <Row className={`text-center ${classes.PlatformSummary}`}>
        <Col md={4} className={classes.PlatformSummaryCard}>
          <h2>60</h2>
          <p>projects funded</p>
        </Col>
        <Col md={4} className={classes.PlatformSummaryCard}>
          <h2>1 565 432 USDT</h2>
          <p>towards creative projects</p>
        </Col>
        <Col md={4} className={classes.PlatformSummaryCard}>
          <h2>5123</h2>
          <p>backings</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PlatformSummary;