import { useStoreState } from 'easy-peasy';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Row, Container, Col, ProgressBar, Modal, Form } from 'react-bootstrap';
import ProjectTag from './ProjectTag/ProjectTag';
import BlockchainService from '../../services/blockchainService';
import ProjectService from '../../services/projectService';
import Spinner from '../../components/common/Spinner/Spinner';
import classes from './ProjectDetails.module.scss';

const ProjectDetails = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState();
  const [percentageTime, setPercentageTime] = useState(0);
  const [percentageAmount, setPercentageAmount] = useState(0);
  const [modalTxShow, setModalTxShow] = useState(false);
  const [userReturn, setUserReturn] = useState(false);
  const [myInvestment, setMyInvestment] = useState();
  const [investmentClaimed, setInvestmentClaimed] = useState(false);

  const { account } = useStoreState((state) => state.walletStore);

  const navigate = useNavigate();

  let interval;
  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      if (project) {
        const now = +(Date.now() / 1000).toFixed()
        const start = (new Date(project.createdOn).getTime() / 1000).toFixed()
        const end = (new Date(project.endDate).getTime() / 1000).toFixed()
        setPercentageTime((100 * (now - start) / (end - start)).toFixed())
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [project]);

  useEffect(() => {
    loadData()
  }, []);

  useEffect(() => {
    if (!project || !account) {
      return;
    }

    userCanReturn(project.endDate, project.totalInvestment, project.targetSum, account.address)
      .then(result => {
        setUserReturn(result);
      })
  }, [project, account])

  useEffect(() => {
    if (!project || !account) {
      return;
    }

    if (project.owner.address !== account.address) {
      return;
    }

    BlockchainService.isInvestmentClaimed(project.campaignId)
      .then(result => {
        setInvestmentClaimed(result);
      })
  }, [project, account])

  useEffect(() => {
    if (!project || !account) {
      setMyInvestment(null);
      return;
    }

    BlockchainService.getMyInvestment(account.address, project.campaignId)
      .then(result => {
        setMyInvestment(ethers.utils.formatUnits(result, 6));
      })
  }, [project, account])

  const loadData = async () => {
    if (!id) {
      navigate('/')
    }

    const data = await ProjectService.details(id)

    const now = +(Date.now() / 1000).toFixed()
    const start = (new Date(data.createdOn).getTime() / 1000).toFixed()
    const end = (new Date(data.endDate).getTime() / 1000).toFixed()
    setPercentageTime((100 * (now - start) / (end - start)).toFixed())
    const info = await BlockchainService.getProjectData(data.campaignId)

    data.totalInvestment = ethers.utils.formatUnits(ethers.BigNumber.from(info.raisedAmount), 6)
    setPercentageAmount((100 * data.totalInvestment / data.targetSum).toFixed())
    setProject(data)
  }

  const investHandler = async (e) => {
    e.preventDefault()
    setShowModal(false)
    setModalTxShow(true)

    const amount = +e.target.elements.amount.value
    await BlockchainService.invest(project.id, project.campaignId, amount, account.address)
    loadData();

    setModalTxShow(false)
  }

  const handleClaim = async (e) => {
    e.preventDefault()
    setModalTxShow(true)

    await BlockchainService.claimInvestment(project.campaignId, account.address)
    loadData();

    setModalTxShow(false)
  }

  const handleReturn = async (e) => {
    e.preventDefault()
    setModalTxShow(true)

    await BlockchainService.returnInvestment(project.campaignId)
    loadData();

    setModalTxShow(false)
  }

  if (!project) {
    return <Spinner />
  }

  function getTimestamp(time) {
    return ((time ? new Date(time).getTime() : new Date().getTime()) / 1000).toFixed();
  }

  function creatorCanClaim(endDate, totalInvestment, targetSum, creator, account) {
    return getTimestamp(endDate) < getTimestamp() &&
      ethers.utils.parseUnits(totalInvestment.toString(), 6).gte(ethers.utils.parseUnits(targetSum.toString(), 6)) &&
      creator === account;
  }

  async function userCanReturn(endDate, totalInvestment, targetSum, account) {
    let userBalance = await BlockchainService.getMyInvestment(account, project.campaignId);

    return getTimestamp(endDate) < getTimestamp() &&
      ethers.utils.parseUnits(totalInvestment.toString(), 6).lt(ethers.utils.parseUnits(targetSum.toString(), 6)) &&
      userBalance.gt(0);
  }

  function formatDate(date) {
    const dateParts = date.split('T')[0].split('-');
    const timeParts = date.split('T')[1].split(':');
    return dateParts.reverse().join('/') + ' ' + timeParts[0] + ':' + timeParts[1];
  }

  return (
    <Container className="mb-5 justify-content-center">
      <Row className={classes.ProjectContainer}>
        <Col md={10} className="mx-auto d-block">
          <Row>
            <Col md={8}>
              <h1 className={``}>{project.name}</h1>
              <p className={`m-0`}>{project.description}</p>
              <div className="d-flex justify-content-between">
                <div className={`mb-3 mt-2 ${classes.TagContainer}`}>
                  {project.tags.map((x, i) => <ProjectTag key={x + i} tag={x} />)}
                </div>

                <p className='mt-2'>
                  <a className={classes.Link} href={project.url} target="_blank" rel="noreferrer noopener">Learn more</a>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <img
                alt="project"
                src={project.imageUrl}
                className={`${classes.Image} rounded`}
              />
            </Col>
            <Col md={4} className="my-3">
              <div className={`mb-3`}>
                <h6 className={`mb-1`}>Author: <span className={`${classes.Names} fw-normal`}>{project.owner.firstName} {project.owner.lastName}</span></h6>
              </div>
              <h6 className="mb-1">Time passed: {percentageTime > 100 ? 100 : percentageTime}%</h6>
              <ProgressBar className={`${classes.ProgressBar}`} variant='success' now={percentageTime > 100 ? 100 : percentageTime} />

              <h6 className="mb-1 mt-4">Amount raised: {percentageAmount}%</h6>
              <ProgressBar className={`${classes.ProgressBar}`} variant='success' now={percentageAmount} />
              <p className="mt-1">{project.raisedSum} USDT pledged of {project.targetSum} USDT goal</p>

              {myInvestment && <h6 className="mb-1">My Contribution: {myInvestment} USDT</h6>}

              {
                account && getTimestamp(project.endDate) >= getTimestamp()
                  ?
                  <button
                    type="button"
                    className={`${classes.Button} fw-bold py-2`}
                    onClick={() => setShowModal(true)}
                  >
                    CONTRIBUTE
                  </button>
                  :
                  ""
              }

              {account && creatorCanClaim(project.endDate, project.totalInvestment, project.targetSum, project.owner.address, account.address)
                ?
                investmentClaimed
                  ?
                  <h6 className="mb-1">Funds are claimed!</h6>
                  :
                  <button className={`${classes.Button} fw-bold  py-2`} variant="warning" onClick={(e) => handleClaim(e)}>
                    Claim Contribution
                  </button>
                :
                ''
              }

              {account && userReturn
                &&
                <button className={`${classes.Button} fw-bold  py-2`} variant="warning" onClick={(e) => handleReturn(e)}>
                  Return Contribution
                </button>
              }

              <p className={`${classes.DateText} mt-2`}>{new Date(project.createdOn).toLocaleString()} - {new Date(project.endDate).toLocaleString()}</p>

            </Col>
          </Row>
        </Col>
      </Row >

      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={classes.InvestModalWrapper}
      >
        <div className={classes.InvestModal}>

          <Modal.Header closeButton className={`${classes.ModalHeader}`}>
            <Modal.Title id="contained-modal-title-vcenter">
              Contribution size
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {project.perks?.length > 0 && <h6 className="fw-bold">Perks:</h6>}
            {project.perks?.map((perk, i) =>
              <div className={`mb-1 ${classes.Perk}`} key={i}>
                <p className="m-0"><span className="fw-bold">{perk.name}:</span> ≥{perk.price} USDT</p>
                <p className={`m-0 ${classes.PerkDescription}`}>{perk.description}</p>
              </div>

            )}
            <Form onSubmit={investHandler} autoComplete="off">
              <Form.Group className="mb-3" controlId="amount">
                <Form.Control type="number" placeholder="Value" className="py-2 mt-2" autoComplete="off" />
              </Form.Group>
              <button className={`${classes.Button} py-2 fw-bold`} type='submit'>CONTRIBUTE</button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={modalTxShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={classes.InvestModalWrapper}
      >
        <div className={classes.InvestModal}>

          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Transaction Pending...
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please wait...
          </Modal.Body>
        </div>
      </Modal>
    </Container >
  );
};

export default ProjectDetails;
