import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Col, Row, Navbar } from 'react-bootstrap';
import { CashStack, Coin, Kanban, PersonBoundingBox, Wallet } from 'react-bootstrap-icons';
import { NavLink, Link } from 'react-router-dom';
import Header from '../../components/common/Header/Header';
import DisconnectWallet from '../../components/WalletConnection/DisconnectWallet/DisconnectWallet';
import { trimAddress } from '../../utils/web3Utils';

import classes from './Layout.module.scss';

const Layout = (props) => {
  const { isWalletConnected, account } = useStoreState((state) => state.walletStore);
  return (
    <Row className={`${classes.Page} m-0`}>
      <Col xs='2' />
      <Col xs='2' className={classes.Navigation}>
        <Link className={classes.Logo} to={"/"}>
          <img src="https://static.wixstatic.com/media/d38d68_6b1a512101614f41b1b9561276308795~mv2.png/v1/fill/w_148,h_36,al_c,usm_0.66_1.00_0.01,enc_auto/Gigix_logo.png" alt="Gigix_logo" />
        </Link>

        <hr />
        {isWalletConnected ?
          <div className={classes.ConnectedMenu}>
            <Navbar.Brand className={`${classes.ConnectedBtn} m-0 w-100 position-relative`}>
              <Wallet />{trimAddress(account.address)}
            </Navbar.Brand>
            <DisconnectWallet />
          </div>
          :
          <NavLink to='/login' className={classes.Connect}>
            <Wallet /> <span>Connect</span>
          </NavLink>
        }
        <hr />
        <NavLink to='/'><CashStack /> <span>Dashboard</span></NavLink>

        {isWalletConnected && account.token ?
          <>
            <NavLink to='/create'><Kanban /> <span>Create project</span></NavLink>
            <NavLink to='/my-projects'><Coin /> <span>My projects</span></NavLink>
          </>
          :
          <NavLink to='/register'><PersonBoundingBox /> <span>Become creator</span></NavLink>
        }

      </Col>
      <Col>
        <Header />
        <div className={classes.Layout}>
          {props.children}
        </div>
      </Col>
    </Row>
  );
}

export default Layout;