import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Container } from 'react-bootstrap';
import PlatformSummary from '../../components/PlatformSummary/PlatformSummary';
import FeaturedProjects from '../../components/FeaturedProjects/FeaturedProjects';
import ProjectsList from '../../components/ProjectsList/ProjectsList';
import ProjectService from '../../services/projectService';

const Home = () => {
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    loadData()
  }, []);

  const loadData = async () => {
    const res = await ProjectService.allActive()
    setProjects(res.sort((a, b) => {
      if (a.isCompleted === b.isCompleted) {
        if (a.endDate < b.endDate) {
          return -1;
        }

        return 1
      } else if (a.isCompleted === false) {
        return -1;
      }

      return 1;
    }));
  };

  const clickHandler = (id) => {
    navigate('/project/details/' + id, { state: { id } });
  }

  return (
    <Container>
      <h1 className="text-center mb-5">
        Creative work shows us what's possible.
        <br />
        Contribute to what you love.
      </h1>
      <PlatformSummary />

      <FeaturedProjects />
      <ProjectsList projects={projects} clickHandler={clickHandler} title="EXPLORE PROJECTS" />
    </Container>
  );
};

export default Home;