import { Row, Col, ProgressBar } from 'react-bootstrap';
import classes from './FeaturedProjectListCard.module.scss';

const FeaturedProjectListCard = () => {
  return (
    <Row className={`${classes.ListCard} mb-3 pb-3`}>
      <div className={`${classes.ListCardContainer} d-flex p-0`}>
        <Col md={5} className="p-0 pe-3">
          <img
            className={`object-cover w-100`}
            src={"https://thehill.com/sites/default/files/styles/article_full/public/ca_internationalwomensday_illustration_istock.jpg?itok=hOev3rFs"}
            alt="project"
          />
          <ProgressBar className={classes.ProgressBar} variant='success' now={62} />
        </Col>

        <Col md={7} className={`${classes.ListCardInfo} p-0 ps-2 py-2 d-flex flex-column justify-content-between`}>
          <h6 className="mb-1">Project name title</h6>
          <p className={`${classes.FundedText} m-0`}><span className="fw-bold">62%</span> funded</p>

          <div>
            <span className={`d-block ${classes.FeaturedProjectExtraInfo} mb-1`}>
              By: <span className={classes.FeaturedProjectCreatorName}>Firsname Lastname</span>
            </span>
            <span className={`d-block ${classes.FeaturedProjectExtraInfo}`} >
              Ends: <span>Thu, March 24 2022 3:59 PM UTC +02:00</span>
            </span>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default FeaturedProjectListCard;