import { ethers } from "ethers";
import { abi as fundABI } from '../assets/abis/Fund.json';
import { abi as tokenABI } from '../assets/abis/Token.json';
import { abi as utilityTokensABI } from '../assets/abis/UtilityTokens.json';
import { toastHandler, TOAST_STATES } from "../helpers/toast";
import ProjectService from "./projectService";
class BlockchainService {
  static createCampaign = async (endTime, targetAmount, address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider.getSigner(address).connectUnchecked())


    const tx = await contract.createCampaign(endTime, ethers.utils.parseUnits(targetAmount, 6)).catch(err => {
      toastHandler({ success: TOAST_STATES.ERROR, message: err.message })
    });

    if (!tx) {
      return
    }

    return provider.waitForTransaction(tx.hash)
      .then((data) => {
        toastHandler({ message: 'Transaction is being mined' })
        const event = contract.interface.parseLog(data.logs[0]);
        return event.args.id.toNumber();
      })
  }

  static invest = async (projectId, id, amount, address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider.getSigner(address).connectUnchecked())
    const token = new ethers.Contract(process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS, tokenABI, provider.getSigner(address).connectUnchecked())

    const parsedAmount = ethers.utils.parseUnits(amount.toString(), 6);

    let tx = await token.approve(contract.address, parsedAmount).catch(err => {
      toastHandler({ success: TOAST_STATES.ERROR, message: err.message });
    });
    if (!tx) {
      return;
    }

    await tx.wait();

    toastHandler({ message: 'Approve tx is being mined' });

    tx = await contract.invest(id, parsedAmount).catch(err => {
      toastHandler({ success: TOAST_STATES.ERROR, message: err.message });
      return
    });
    if (!tx) {
      return;
    }

    await tx.wait();
    await ProjectService.invest(projectId, { amount });
  }

  static claimInvestment = async (projectId, address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider.getSigner(address).connectUnchecked())


    const tx = await contract.claimInvestment(projectId).catch(err => {
      toastHandler({ success: TOAST_STATES.ERROR, message: err.message })
    });

    if (!tx) {
      return
    }

    return provider.waitForTransaction(tx.hash)
  }

  static returnInvestment = async (projectId, address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider.getSigner(address).connectUnchecked())


    const tx = await contract.getBackInvestment(projectId).catch(err => {
      toastHandler({ success: TOAST_STATES.ERROR, message: err.message })
    });

    if (!tx) {
      return
    }

    return provider.waitForTransaction(tx.hash)
  }

  static getProjectData = async (id) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider)
    return await contract.campaigns(id)
  }

  static getMyInvestment = async (account, projectId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_UTILITY_TOKENS_CONTRACT_ADDRESS, utilityTokensABI, provider)
    return await contract.balanceOf(account, projectId);
  }

  static isInvestmentClaimed = async (projectId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const contract = new ethers.Contract(process.env.REACT_APP_FUND_CONTRACT_ADDRESS, fundABI, provider)
    return await contract.investmentClaimed(projectId);
  }
}

export default BlockchainService;
