import { Card, Col, ProgressBar } from 'react-bootstrap';
import classes from './ProjectCard.module.scss';

const ProjectCard = ({ project, clickHandler }) => {
  return (
    <Col md={4} className={`mb-5 ${classes.ProjectCardWrapper}`} onClick={() => clickHandler(project.id)}>
      <Card className={classes.ProjectCardContainer}>
        <img
          src={project.imageUrl}
          className={`w-100 ${classes.ProjectImg}`}
          alt="project"
        />
        <ProgressBar className={`${classes.ProgressBar} position-relative`} variant='success' now={(+project.raisedSum / +project.targetSum) * 100} />
        <p className={`${classes.MoneyRaised} text-center mb-0`}>
          <span className={`${classes.Sum} fw-bold`}>{project.raisedSum} USDT</span> raised
        </p>

        <div className="mt-2 px-2">
          <Card.Title className={`${classes.ProjectTitle}`}>{project.name}</Card.Title>
          <Card.Text className={classes.ProjectDescription}>
            {project.description}
          </Card.Text>

          <div className="d-flex justify-content-between mb-2">
            <span className={`${classes.FeaturedProjectExtraInfo}`}>
              By: <span className={classes.FeaturedProjectCreatorName}>{`${project.owner?.firstName} ${project.owner?.lastName}`}</span>
            </span>

            <span className={`${classes.FeaturedProjectExtraInfo}`}>
              Ends: {new Date(project.endDate).toDateString()}
            </span>
          </div>
        </div>
      </Card>
    </Col>
  )
};

export default ProjectCard;