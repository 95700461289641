import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import UserService from '../../services/userService';
import { ReactComponent as YouTubeIcn } from '../../assets/icons/youtube.svg';
import { ReactComponent as InstagramIcn } from '../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcn } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcn } from '../../assets/icons/twitter.svg';
import { PersonBoundingBox, PersonVideo3, PersonBadgeFill, EnvelopeFill, PersonCircle } from 'react-bootstrap-icons';

import classes from './Register.module.scss';
import ConnectWallet from '../WalletConnection/ConnectWallet/ConnectWallet';

const Register = (props) => {
  const { isWalletConnected, account } = useStoreState((state) => state.walletStore);
  const { login } = useStoreActions((actions) => actions.walletStore);

  const submitHandler = async (e) => {
    e.preventDefault()

    const data = {}
    const elements = Object.getOwnPropertyNames(e.target.elements)
    elements.slice(elements.length / 2 + 1, elements.length).forEach((el) => {
      data[el] = e.target.elements[el].value
    })

    const info = await UserService.register({ address: account.address })

    await login(info.nonce)
    await UserService.setPersonalData({ ...data, ...account })
  }

  if (!isWalletConnected) {
    return (<ConnectWallet />);
  } else {
    return (
      <Container className={`mb-5`}>
        <Row className={`w-100 justify-content-center`}>
          <Col className={`${classes.FormWrapper} py-5`} md={7}>
            <h2 className="text-center mb-5">BECOME A CREATOR</h2>
            <Form className={`${classes.Form} form-floating`} onSubmit={submitHandler}>
              <div className="d-flex">
                <Form.Group className="mb-3 me-2 position-relative" controlId="firstName">
                  <PersonBadgeFill color={'#1B3168'} />
                  <Form.Control required type="text" placeholder="First name" className="py-2" />
                </Form.Group>
                <Form.Group className="mb-3 ms-2 position-relative" controlId="lastName">
                  <PersonBadgeFill color={'#1B3168'} />
                  <Form.Control required type="text" placeholder="Last name" className="py-2" />
                </Form.Group>
              </div>

              <Form.Group className="mb-3 position-relative" controlId="email">
                <EnvelopeFill color={'#1B3168'} />
                <Form.Control required type="email" placeholder="Email" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="username">
                <PersonCircle color={'#1B3168'} />
                <Form.Control type="text" placeholder="Username" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="avatar">
                <PersonBoundingBox color={'#1B3168'} />
                <Form.Control type="text" placeholder="URL to profile photo" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="coverPhoto">
                <PersonVideo3 color={'#1B3168'} />
                <Form.Control type="text" placeholder="URL to cover photo" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="youtubeUrl">
                <YouTubeIcn />
                <Form.Control type="text" placeholder="URL to YouTube profile" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="instagramUrl">
                <InstagramIcn />
                <Form.Control type="text" placeholder="URL to Instagram profile" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="facebookUrl">
                <FacebookIcn />
                <Form.Control type="text" placeholder="URL to Facebook profile" className="py-2" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="twitterUrl">
                <TwitterIcn />
                <Form.Control type="text" placeholder="URL to Twitter profile" className="py-2" />
              </Form.Group>
              <Button variant="primary" type="submit" className={`w-100 fw-bold py-2`}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Register;