import { Row, Col, ProgressBar, Container } from 'react-bootstrap';
import FeaturedProjectListCard from './FeaturedProjectListCard/FeaturedProjectListCard';
import classes from './FeaturedProjects.module.scss';

const FeaturedProjects = () => {
  return (
    <Container className="my-5">
      <h6>FEATURED PROJECTS</h6>
      <Row>
        <Col md={6} className={`${classes.MainFeaturedProject} py-4 pe-4 me-5`}>

          <div className={classes.FeaturedProject}>
            <img
              className={`object-cover w-100 ${classes.FeaturedProjectImg}`}
              src={'https://www.liveabout.com/thmb/pwO4o_iDrZRTmmhs7eOfD25Qoqw=/1500x1125/smart/filters:no_upscale()/pop-music-57bce3863df78c87634ea806.jpg'}
              alt={'project'}
            />
            <ProgressBar className={classes.ProgressBar} variant='success' now={62} />
            <div className="px-3 pb-3">
              <h4 className={`mt-3 ${classes.FeaturedProjectTitle}`}>Music event - <span className={classes.FundedText}>62% funded</span></h4>
              <p className={`mt-2 mb-1 ${classes.FeaturedProjectDescription}`}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, inventore.</p>
              <div className={`mt-2 ${classes.FeaturedProjectExtraInfo} d-flex justify-content-between`}>
                <span>
                  By: <span className={classes.FeaturedProjectCreatorName}>Georgi Georgiev</span>
                </span>

                <span>
                  Ends: <span>Thu, March 24 2022 3:59 PM UTC +02:00</span>
                </span>
              </div>
            </div>
          </div>
        </Col>

        <Col md={5} className={`${classes.FeatureProjectsList} py-4`}>
          <FeaturedProjectListCard />
          <FeaturedProjectListCard />
          <FeaturedProjectListCard />
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturedProjects;