import { useStoreState } from 'easy-peasy';
import React, { useState } from 'react';
import { Button, Col, Form, ListGroup, Row, Modal, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import BlockchainService from '../../services/blockchainService';
import ProjectService from '../../services/projectService';
import ConnectWallet from '../WalletConnection/ConnectWallet/ConnectWallet';

import classes from './CreateProject.module.scss';

const CreateProject = (props) => {
  const [perks, setPerks] = useState([]);
  const { account } = useStoreState((state) => state.walletStore);
  const [modalTxShow, setModalTxShow] = useState(false);

  let navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault()

    const data = {}
    const elements = Object.getOwnPropertyNames(e.target.elements)
    elements.slice(elements.length / 2 + 1, elements.length).forEach((el) => {
      data[el] = e.target.elements[el].value
    })

    data.perks = [...perks]

    data.tags = data.tags.trim().split(' ');
    data.endDate = new Date(data.endDate).toUTCString();
    setModalTxShow(true);
    const res = await BlockchainService.createCampaign((new Date(data.endDate).getTime() / 1000).toFixed(), data.targetSum, account.address)
    if (!res) {
      return;
    }

    await ProjectService.create({ ...data, campaignId: res, tokenId: res })
    setModalTxShow(false);
    navigate(`/my-projects`);
  }

  const addPerkHandler = (e) => {
    e.preventDefault()

    const data = {}
    const elements = Object.getOwnPropertyNames(e.target.elements)
    elements.slice(elements.length / 2 + 1, elements.length).forEach((el) => {
      data[el] = e.target.elements[el].value
      e.target.elements[el].value = ''
    })
    setPerks([...perks, data]);
  }

  const deletePerk = (i) => {
    let perksTemp = [...perks]
    perksTemp.splice(i, 1)
    setPerks(perksTemp)
  }

  return (
    <>
      <Container className={`mb-5`}>
        <Row className={`w-100 justify-content-center`}>
          <Col className={`${classes.FormWrapper} pb-3`} md={9}>
            <h2 className="text-center my-5">CREATE A PROJECT</h2>
            <Row className={`justify-content-center`}>
              <Col className={`${classes.Form} pb-5 ps-4`} md={5}>
                <h4>PROJECT INFO</h4>
                <Form onSubmit={submitHandler}>
                  <Form.Group className="mb-2" controlId="name">
                    <Form.Label className="mb-1">Name</Form.Label>
                    <Form.Control required className="py-2" type="text" placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="tags">
                    <Form.Label className="mb-1">Tags</Form.Label>
                    <Form.Control className="py-2" type="text" placeholder="Tags" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="description">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <Form.Control required className="py-2" type="textarea" placeholder="Description" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="url">
                    <Form.Label className="mb-1">Link</Form.Label>
                    <Form.Control className="py-2" type="text" placeholder="Link to project" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="imageUrl">
                    <Form.Label className="mb-1">Image link</Form.Label>
                    <Form.Control className="py-2" type="text" placeholder="Link to project image" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="targetSum">
                    <Form.Label className="mb-1">Target amount</Form.Label>
                    <Form.Control required className="py-2" type="number" placeholder="Target USDT" />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="endDate">
                    <Form.Label className="mb-1">End date</Form.Label>
                    <Form.Control required className="py-2" type="datetime-local" placeholder="End date" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col className={`${classes.Form} pe-5`} md={5}>
                <h4>ADD PERKS</h4>
                <Form onSubmit={addPerkHandler}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-2" controlId="name">
                        <Form.Label className="mb-1">Heading</Form.Label>
                        <Form.Control required className="py-2" type="text" placeholder="Name of perk" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-2" controlId="price">
                        <Form.Label className="mb-1">Price</Form.Label>
                        <Form.Control required className="py-2" type="number" placeholder="Price" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-2" controlId="description">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <Form.Control className="py-2" as="textarea" placeholder="Description" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="d-block mx-auto">
                    Add
                  </Button>
                </Form>
                {perks.map((perk, i) =>
                  <ListGroup className={`${classes.Perk} my-2`} key={i}>
                    <ListGroup.Item className="py-1">Perk: <span>{perk.name}</span></ListGroup.Item>
                    <ListGroup.Item className="py-1">Price: <span>{perk.price}</span></ListGroup.Item>
                    <ListGroup.Item className="py-1">Description: <span>{perk.description.substring(0, 15)}...</span></ListGroup.Item>
                    <Button className="py-1" onClick={() => deletePerk(i)}>Delete</Button>
                  </ListGroup>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalTxShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={classes.InvestModalWrapper}
      >
        <div className={classes.InvestModal}>

          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Transaction Pending...
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please wait...
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default CreateProject;