import { Row, Col, Container } from 'react-bootstrap';
import ProjectCard from './ProjectCard/ProjectCard';

const ProjectsList = ({ projects, clickHandler, title }) => {
  return (
    <Container className="my-5">
      <h6>{title}</h6>
      <Row className="mt-4">
        {projects.map(x => <ProjectCard key={x.id} project={x} clickHandler={clickHandler} />)}
      </Row>
    </Container>
  );
};

export default ProjectsList;